import { Person, PeopleApi } from 'api'
import { Model } from 'api'
import { Formatter } from 'shared'
import { SmartButtonStub } from 'ui/smart-buttons'
import { Multiselect } from 'multiselect-react-dropdown';

const driveQueryVariable = value => {
  let stringifyValue = '';
  delete value['fran_amount']
  let keys = Object.keys(value)
  keys.forEach((key, index) => {
    stringifyValue = stringifyValue + `${key}:${value[key]}${index == keys.length - 1 ? '': '-'}`
  })
  return stringifyValue;
}

export default class EventReservationBlock extends Component {
  constructor(props) {
    super(props)
    let { event } = this.props
    let defaultTicketTypeName = event.defaultTicketType && event.defaultTicketType.type
    this.state = { 
        tickets: { [defaultTicketTypeName]: (event.isFamily ? 0 : 1) },
        selectedFamilyMembers: []
      }
  }

  get selectedOccurrenceId() {
    let { event, subroute } = this.props
    return Model.extractSubrouteArgs(subroute, 0) || event.hasReservableOccurrences && event.reservableOccurrences[0].id
  }

  render() {
    let { event, base, subroute } = this.props
    let { tickets, selectedFamilyMembers } = this.state;
    let linked_users_json = PeopleApi.kick('linked_users', {})

    if (event.requiresPayment && !application.user.hasCard) return this.renderCardRequired()

    return (
      <div className="EventReservation-form">
        <h3 className="EventReservation-heading">Reservation Details</h3>
        {event.hasReservableOccurrences &&
          <div className="EventReservation-occurrences">
            <select className='form-control' onChange={this.occurrenceSelected} value={this.selectedOccurrenceId}>
              {event.reservableOccurrences.map(occurrence =>
                <option value={occurrence.id} key={occurrence.id}>{occurrence.displayName}</option>
              )}
            </select>
          </div>
        }
        <div className="EventReservation-ticket-types">
          <table className="table">
            <tbody>
            { !event.isFamily &&
              event.ticket_settings && event.ticket_settings.map(ticketSetting =>
                <tr key={ticketSetting.type}>
                  <td>{ticketSetting.type}</td>
                  <td>{Formatter.amountFromCents(ticketSetting.amount)}</td>
                  <td className="text-right">
                    <button type='button' className='btn btn-outline-primary btn-sm ticket-btn' onClick={() => this.inc(ticketSetting.type, -1)}>–</button>
                    <span className='counter'>{tickets[ticketSetting.type] || 0}</span>
                    <button type='button' className='btn btn-outline-primary btn-sm ticket-btn' onClick={() => this.inc(ticketSetting.type, +1)}>+</button>
                  </td>
                </tr>
              )
            }              
              { event.isFamily &&
                <tr>
                  <td colSpan="3">
                    <h6>Select Family Profile</h6>
                    <Multiselect
                      options={linked_users_json}                    
                      onSelect={this.onSelect}
                      onRemove={this.onRemove}
                      displayValue="name"
                    />                  
                  </td>
                </tr>
              }  
            </tbody>
          </table>
        </div>
        <div className="EventReservation-action">
          <SmartButtonStub text={event.btn_text || "Reserve"} click={this.reserve} />
          <Link to={`${base}/${event.uid}`} className='btn smart-button'>Cancel</Link>
        </div>
      </div>
    )
  }

  reserve = async () => {
    let { event, base, reloadSmartButtons } = this.props
    let { tickets, selectedFamilyMembers } = this.state
    let { selectedOccurrenceId } = this
    let result = await event.reserve(selectedOccurrenceId || event.id, tickets, selectedFamilyMembers)
    if (result) {
      application.go(`${base}/${event.uid}`)
      reloadSmartButtons()
    }
  }


  occurrenceSelected = (e) => {
    let { event, base } = this.props
    application.go(`${base}/${event.uid}::reserve:${e.target.value}`)
  }

  onSelect = (selectedList, selectedItem) => {        
    let family_profile_ids = selectedList.map(r => r.id);
    this.setState({ selectedFamilyMembers: family_profile_ids })
  }

  onRemove = (selectedList, removedItem) => {
    let family_profile_ids = selectedList.map(({id}) => ({ id }));
    this.setState({ selectedFamilyMembers: family_profile_ids })
  }

  inc(ticketTypeName, count) {
    let tickets = { ...this.state.tickets, [ticketTypeName]: (this.state.tickets[ticketTypeName] || 0) + count }
    this.setState({ tickets })
  }

  renderCardRequired() {
    return <div className="EventReservation-card-required">
      <p>You need to set up a credit card to reserve events.</p>
      <Link to="/settings/payment" onClick={this.didMovedToCardSettings}>Add a Credit Card</Link>
    </div>
  }
}
